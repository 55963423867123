/* Homepage.css */

.homepage-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
}

.homepage-title {
  text-align: left;
  margin: 32px 0;
  font-size: 34px;
  line-height: 1.2;
}

.tag-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 24px 0;
  padding-right: 16px;
}

.articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-bottom: 48px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .homepage-container {
    padding: 16px;
  }

  .hero-section {
    display: none !important; /* Hide hero section on mobile */
  }

  .homepage-title {
    font-size: 24px;
    margin: 16px 0;
  }

  .articles {
    grid-template-columns: 1fr; /* Single column on mobile */
    gap: 24px;
  }

  .tag-container {
    width: 100%;
    padding-right: 0;
    margin: 16px 0;
  }
}

/* Responsive breakpoints */
@media (max-width: 1200px) {
  .articles {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

/* Loading states */
.loading-indicator {
  text-align: center;
  padding: 24px;
  color: #666;
}

.error-message {
  text-align: center;
  padding: 24px;
  color: #dc3545;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add these new styles */
.article-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.article-meta {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 14px;
  flex: 1; /* Allow meta to take available space */
  min-width: 0; /* Allow text truncation if needed */
}

.read-more-button {
  white-space: nowrap;
  margin-left: 16px; /* Add some space between meta and button */
  flex-shrink: 0; /* Prevent the button from shrinking */
}

/* Filter Section Styles */
.filter-section {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  width: 100%;
  flex-wrap: nowrap;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
  padding: 0;
  flex: 1;
}

/* Date Filter Container */
.date-filter-container {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 200px; /* Ensure minimum width for date filter */
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .filter-section {
    flex-wrap: wrap;
    gap: 12px;
  }

  .tag-container {
    width: 100%;
    justify-content: flex-start;
    gap: 8px;
  }

  .date-filter-container {
    width: 100%;
    justify-content: flex-start;
  }

  /* Adjust tag buttons for mobile */
  .tag-container button {
    font-size: 13px;
    padding: 6px 12px;
  }

  /* Make date filter input full width on mobile */
  .date-filter-container input {
    width: 100%;
    max-width: none;
  }
}

/* Tablet adjustments */
@media (min-width: 769px) and (max-width: 1024px) {
  .filter-section {
    gap: 12px;
  }

  .tag-container {
    flex: 1;
  }

  .date-filter-container {
    flex: 0 0 auto;
  }
}

/* Pagination Styles */
.pagination-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
  color: #333333;
}

.pagination-info p {
  margin-bottom: 16px;
  font-size: 14px;
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  background: white;
  color: #333333;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.pagination button:hover {
  background: #f5f5f5;
  border-color: #d0d0d0;
}

.pagination button.active {
  background: #1976d2;
  color: white;
  border-color: #1976d2;
}

.pagination button:disabled {
  background: #f5f5f5;
  color: #999999;
  cursor: not-allowed;
  border-color: #e0e0e0;
}

/* Add ellipsis styling */
.pagination .ellipsis {
  color: #333333;
  padding: 8px;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pagination {
    gap: 4px;
  }

  .pagination button {
    padding: 6px 12px;
    font-size: 13px;
  }
}

.results-info {
  margin: 20px 0;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 8px;
  color: #333;
  font-size: 14px;
}

.pagination-container {
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.pagination-info {
  color: #666;
  font-size: 14px;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pagination-button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f5f5f5;
}

.pagination-button:not(:disabled):hover {
  background: #f0f7ff;
  border-color: #1976d2;
  color: #1976d2;
}

.page-number {
  font-size: 14px;
  color: #333;
  min-width: 24px;
  text-align: center;
}

@media (max-width: 768px) {
  .pagination-container {
    margin: 16px 0;
  }

  .pagination-controls {
    gap: 12px;
  }

  .pagination-button {
    padding: 6px 12px;
  }
}
  