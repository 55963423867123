.article-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: #fff; /* Ensure contrast against text */
  position: relative; /* Enable positioning of child elements */
  height: 100%;
  min-height: 350px; /* Reduced from 400px */
}

.article-image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
  background-color: #f5f5f5; /* Placeholder color */
}

.article-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

/* Optional hover effect */
.article-image-container:hover .article-image {
  transform: scale(1.05);
}

.article-tag {
  position: absolute;
  top: 8px; /* Position from the top of the card */
  left: 8px; /* Position from the left side of the card */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: #fff; /* Contrast text color */
  padding: 4px 8px; /* Add padding for better visibility */
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px; /* Rounded edges */
  text-transform: uppercase;
  z-index: 1; /* Ensure it appears above the image */
}

.article-text-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.article-title {
  font-size: 18px; /* Slightly larger font */
  font-weight: 600;
  line-height: 1.3; /* Improve readability */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit title to 3 lines */
  -webkit-box-orient: vertical;
  color: #333;
  margin: 0; /* Remove default margin */
  margin-bottom: auto;
  min-height: 72px; /* Approximately 3 lines of text */
}

.article-meta {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between date/author and button */
  width: 100%; /* Ensure full width */
  margin-top: 16px;
}

.article-meta-left {
  display: flex;
  gap: 8px; /* Space between date and author */
  flex-wrap: wrap;
  align-items: center; /* Align items vertically */
}

.article-date {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap; /* Prevent date from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

.article-author {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap; /* Prevent author from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

.article-button {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-weight: 900;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: color 0.3s ease, background-color 0.3s ease;
  white-space: nowrap; /* Prevent button text from wrapping */
  flex-shrink: 0; /* Prevent button from shrinking */
  margin-left: 8px; /* Add space between author and button */
}

.article-button:hover,
.article-button:focus {
  color: rgba(0, 0, 0, 0.8);
  background-color: #e0e0e0;
}

/* Update image container and image styling - specifically for article cards */
.article-card .article-image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
  background-color: #f5f5f5;
}

.article-card .article-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

/* Scope hover effect to article cards only */
.article-card .article-image-container:hover .article-image {
  transform: scale(1.05);
}


/* Add these mobile-specific styles */
@media (max-width: 768px) {
  .article-card {
    min-height: 300px; /* Further reduce minimum height on mobile */
  }

  .article-image-container {
    padding-top: 50%; /* Slightly shorter aspect ratio for mobile */
  }

  .article-text-area {
    padding: 12px; /* Add padding for better spacing */
  }

  .article-title {
    font-size: 16px; /* Slightly smaller font on mobile */
    min-height: auto; /* Remove fixed height */
    margin-bottom: 8px; /* Reduce space after title */
  }

  .article-meta {
    margin-top: 8px; /* Reduce top margin */
  }

  .article-date,
  .article-author {
    font-size: 11px; /* Smaller text on mobile */
  }

  .article-button {
    padding: 2px 6px; /* Smaller padding */
    font-size: 12px; /* Smaller text */
  }
}
