@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


body, button, input, textarea, select {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

h1 {
  font-size: 48px;
  line-height: 105%;
}

h2 {
  font-size: 34px;
  line-height: 105%;
}

h3 {
  font-size: 24px;
  line-height: 105%;
}

p, .paragraph {
  font-size: 16px;
  line-height: 120%;
}

.tagline, .tag {
  font-size: 12px;
  line-height: 105%;
}
