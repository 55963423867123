/* General Styles */
body {
  font-family: 'Arial', sans-serif; /* Clean, modern font */
  line-height: 1.6;
  margin: 0;
  padding: 0;
  color: #333;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

header,
footer {
  text-align: center;
  margin-bottom: 30px;
}

/* Hero Section */
.content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background: #f9f9f9;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-description {
  flex: 1;
  text-align: left;
}

.contact-description h1 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 20px;
}

.contact-description p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.contact-page-hero-image {
  flex: 1;
  max-width: 45%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

/* Contact Form Section */
.contact-form-container {
  margin: 40px 0;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  font-size: 2rem;
  color: #222;
  margin-bottom: 10px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #0d47a1;
  outline: none;
  box-shadow: 0 0 4px rgba(13, 71, 161, 0.5);
}

.contact-form .submit-button {
  padding: 12px 20px;
  font-size: 1.1rem;
  background-color: #0d47a1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form .submit-button:hover {
  background-color: #0b3a87;
}

/* Loading State */
.loading-container {
  padding-top: 100px;
  text-align: center;
}

/* Responsive Design */
@media only screen and (max-width: 767px) {
  .content-row {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .contact-page-hero-image {
    max-width: 100%;
    margin-top: 20px;
  }

  .contact-description h1 {
    font-size: 2rem;
  }

  .contact-form-container {
    padding: 15px;
  }
}
