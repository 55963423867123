/* src/components/Ad/Ad.css */
.ad-space {
    margin: 20px 0;
    text-align: center;
  }
  
  .ad-space ins {
    display: inline-block;
    width: 100%;
    height: auto;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .ad-space ins {
      width: 100%;
      height: auto;
    }
  }
  