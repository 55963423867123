/* Footer.css */

.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}

.footer-row {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.footer-row:first-child {
  flex-direction: column;
  text-align: center;
}

.link-column-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;
}

.link-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-column-title {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.link-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.footer-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  margin-bottom: 8px;
  transition: color 0.3s;
  text-align: center;
}

.footer-link:hover {
  color: #fff;
}

.footer-text-social-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.footer-text {
  flex-grow: 1;
  text-align: center;
  margin-top: 50px;

}

.social-media-icons {
  display: flex;
  gap: 15px;
}



@media (max-width: 768px) {
  .footer {
    padding: 20px 20px;
  }

  .footer-row {
    flex-direction: column;
    align-items: center;
  }

  .link-column-container {
    flex-direction: row;
    justify-content: center;
  }

  .social-media-icons {
    flex-direction: row;
    justify-content: center;
  }
}
