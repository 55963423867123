/* General Styles */


.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Hero Section */
.about-hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background: #f9f9f9;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-hero-image {
  flex: 1;
  max-width: 45%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

/* Responsive Design */
@media only screen and (max-width: 767px) {
  .about-hero-section {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .about-hero-image {
    max-width: 100%;
    margin-top: 20px;
  }
}