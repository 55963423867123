/* NavigationMenu.css */
/* Desktop styles */
.desktop-navigation-menu {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.navigation-item {
  padding: 10px 20px;
  margin: 0 10px; 
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
}

.navigation-item:hover,
.navigation-item:focus {
  background-color: transparent;
  color: black;
}

.navigation-item-highlighted {
  background-color: black;
  color: white;
  border-radius: 8px;
}

.navigation-item:last-child {
  margin-right: 0;
}

/* Mobile styles */
.mobile-menu-container {
  display: none; /* Hide mobile menu by default */
}

@media (max-width: 768px) {
  .desktop-navigation-menu {
    display: none; /* Hide desktop menu on mobile */
  }

  .mobile-menu-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    z-index: 99;
    flex-direction: column;
  }

  .mobile-menu-container.nav-active {
    display: flex;
  }

  .mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #333;
  }

  .mobile-logo {
    height: 85px;
    width: auto;
    object-fit: contain;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    cursor: pointer;
    z-index: 102;
    padding: 10px;
  }

  .menu-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mobile-navigation-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0 0 0;
  }

  .mobile-navigation-menu .navigation-item {
    padding: 15px 20px;
    margin: 0;
    border-bottom: 1px solid #333;
    width: 100%;
    box-sizing: border-box;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
  }

  .mobile-navigation-menu .navigation-item-highlighted {
    background-color: #333;
    color: #ffffff;
    border-radius: 0;
  }

  .social-media-container {
    display: flex;
    justify-content: center;
    gap: 24px;
    padding: 20px 0 60px 0;
    background-color: #000000;
    border-top: 1px solid #333;
    margin-top: auto;
    width: 100%;
  }

  .social-media-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .social-media-link img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}