/* LocalNewsPage.css */
.articles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    margin-top: 1rem;

  }
  .local-news-page-title {
    text-align: center;
    margin-top: 20px;
  }