/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 85px;
    background-color: #FFF;
}

.hamburger-container {
    display: none; /* Hide by default on desktop */
}

@media (max-width: 768px) {
    .header {
        padding: 8px 16px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 100;
        background-color: #ffffff;
        box-sizing: border-box;
        transition: transform 0.3s ease-in-out;
    }

    .header.hidden {
        transform: translateY(-100%);
    }

    .header.visible {
        transform: translateY(0);
    }

    .hamburger-container {
        display: flex; /* Show on mobile */
        align-items: center;
        height: 85px;
        margin: 0;
        padding: 0;
    }

    .hamburger {
        height: 30px;
        width: 30px; /* Added explicit width */
        cursor: pointer;
        color: #000000; /* Added color for the icon */
        display: block; /* Ensure visibility */
    }
}

