/* Tag.css */
.tag {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tag:hover {
  background-color: #000000;
  color: #ffffff;
}

.selected {
  background-color: #000000;
  color: #ffffff;
}

@media (max-width: 768px) {
  .tag {
    padding: 8px 16px;
    border-radius: 20px;
    background-color: white;
    border: 1px solid #cccccc;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    color: black;
  text-decoration: none;
  -webkit-text-decoration: none;
  text-decoration: none;
}
  

  .tag:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .selected {
    background-color: #000000;
    color: #ffffff;
  }
}  