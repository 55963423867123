/* SocialMedia.css */

.social-media-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 20px 0;
    min-height: 40px;
  }
  
  .social-media-link {
    display: inline-block;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }
  
  .social-media-link img {
    display: block;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    object-fit: contain;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .social-media-link {
      width: 40px;
      height: 40px;
    }
  }
  