/* Button.css */
.button {
  padding: 12px 24px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.button-highlighted {
}

.button:hover {
  background-color: #333;
}
