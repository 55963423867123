/* Target ABC-7 specific structure */
.articles-container article {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Show only first instance of title and content */
.article-content--header:first-of-type,
.article-content--body-text:first-of-type {
  display: block !important;
}

/* Hide duplicate content */
.article-content--header:not(:first-of-type),
.article-content--body-text:not(:first-of-type),
.share-content,
.article-social-branding,
.article-branding,
.mobile {
  display: none !important;
}

/* Style the content */
.article-content--body-text {
  max-width: 800px !important;
  margin: 0 auto !important;
  padding: 0 20px !important;
  font-family: 'Roboto', sans-serif !important;
  line-height: 1.6 !important;
  color: #333 !important;
}

/* Style paragraphs */
.article-content--body-text p {
  margin-bottom: 1.2em !important;
  font-size: 1.1em !important;
  line-height: 1.6 !important;
}

/* Style dateline */
.article-content--body-text .dateline {
  display: block !important;
  margin: 1em 0 1.5em !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
  color: #555 !important;
}

/* Preserve ad spaces */
.ad-space,
.ad-leaderboard,
.ad-rectangle {
  display: block !important;
  margin: 20px auto;
  text-align: center;
}

/* Additional spacing for ads */
.ad-top {
  margin-bottom: 20px;
}

.ad-in-article {
  margin: 30px auto;
}

.ad-footer {
  margin-top: 40px;
}

/* Hide ad placeholder text */
.ad-label {
  display: none !important;
}

/* Make images responsive */
.article-content img,
.article-content--body-text img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1.5em auto;
}

/* Add some spacing around images */
figure {
  margin: 1.5em 0;
  max-width: 100%;
}

figcaption {
  font-size: 0.9em;
  color: #666;
  text-align: center;
  margin-top: 0.5em;
}

/* Ensure images don't overflow on mobile */
@media screen and (max-width: 768px) {
  .article-content,
  .articles-container article {
    padding: 15px;
  }
  
  .article-content img,
  .article-content--body-text img {
    width: 100%;
    margin: 1em auto;
  }
}

/* Hide ad placeholder text more aggressively */
.ad-space::before,
.ad-space::after,
.ad-leaderboard::before,
.ad-leaderboard::after,
.ad-rectangle::before,
.ad-rectangle::after,
.ad-label,
[class*="ad-"]::before,
[class*="ad-"]::after {
  content: none !important;
  display: none !important;
}

/* Improve image responsiveness */
.article-content img,
.article-content--body-text img,
.article-content--header img {
  width: 100% !important;
  max-width: 800px !important;
  height: auto !important;
  object-fit: contain !important;
  margin: 1em auto !important;
}

/* Container for images */
.article-content figure,
.article-content--body-text figure {
  width: 100% !important;
  max-width: 800px !important;
  margin: 1.5em auto !important;
  padding: 0 !important;
}

/* Mobile optimization */
@media screen and (max-width: 768px) {
  .article-content img,
  .article-content--body-text img,
  .article-content--header img,
  .article-content figure,
  .article-content--body-text figure {
    width: 100% !important;
    margin: 1em auto !important;
    padding: 0 15px !important;
  }
}

/* Article image styling */
.articleImage {
  width: 100% !important;
  max-width: 800px !important;
  height: auto !important;
  object-fit: contain !important;
  margin: 1em auto !important;
  display: block !important;
}

/* Mobile optimization for article image */
@media screen and (max-width: 768px) {
  .articleImage {
    width: calc(100% - 30px) !important;
    margin: 1em auto !important;
    padding: 0 !important;
  }
  
  .articleContainer {
    padding: 15px;
  }
}

/* Hide Fox4Now related articles section */
.List,
.List-description,
.List-items,
.List-items-row,
.List-pagination,
.ad--container {
  display: none !important;
}

/* In case the structure changes, target common class names */
[class*="related"],
[class*="List-"],
.ListItem,
div[class*="List"] {
  display: none !important;
}

/* Hide ABC-7 Top Picks section */
.article-content--footer,
.article-footer--header,
.grid-content.horizontal-list,
.horizontal-list-items,
.filmstrip,
[class*="grid-content article"],
.no-cream {
  display: none !important;
}

/* Additional selectors to ensure complete removal */
div[class*="article-content--footer"],
div[class*="grid-content"],
.feed-item-title,
.feed-item-utils,
[class*="horizontal-list"] {
  display: none !important;
}

/* Show article images with specific classes */
.aspect-ratio-original,
img[class*="aspect-ratio"],
.lazyload {
  display: block !important;
  max-width: 100% !important;
  height: auto !important;
  margin: 1em auto !important;
}

/* Ensure proper image loading */
img[data-src] {
  opacity: 1 !important;
  visibility: visible !important;
}

/* Container styling for NBC-2 images */
.image-container,
[class*="aspect-ratio-container"] {
  display: block !important;
  max-width: 800px !important;
  margin: 1em auto !important;
}

/* Mobile optimization for NBC-2 images */
@media screen and (max-width: 768px) {
  .aspect-ratio-original,
  img[class*="aspect-ratio"] {
    width: calc(100% - 30px) !important;
    padding: 0 15px !important;
  }
}

/* Hide loading articles message */
.loading-articles,
div[class*="loading-articles"],
[class*="loading-"] {
  display: none !important;
  visibility: hidden !important;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Remove side floater */
.article-content--body-wrapper-side-floater,
#cream-inline-rec {
  display: none !important;
}

/* Mobile optimization */
@media screen and (max-width: 768px) {
  .article-content--body-text {
    padding: 0 15px !important;
  }
}

/* Style Fox4Now article content */
.RichTextArticleBody-body {
  max-width: 800px !important;
  margin: 0 auto !important;
  padding: 0 20px !important;
  font-family: 'Roboto', sans-serif !important;
  line-height: 1.6 !important;
  color: #333 !important;
}

.RichTextArticleBody-body p {
  margin-bottom: 1.2em !important;
  font-size: 1.1em !important;
  line-height: 1.6 !important;
}

/* Hide Fox4Now related articles section */
.List,
.List-description,
.List-items,
.List-items-row,
.List-pagination,
.ad--container {
  display: none !important;
}

/* In case the structure changes, target common class names */
[class*="related"],
[class*="List-"],
.ListItem,
div[class*="List"] {
  display: none !important;
}

/* Mobile optimization */
@media screen and (max-width: 768px) {
  .RichTextArticleBody-body {
    padding: 0 15px !important;
  }
}

/* Style video transcripts */
.transcript,
div[data-video-id] {
  text-transform: lowercase !important;
  font-family: 'Roboto', sans-serif !important;
  line-height: 1.6 !important;
  color: #333 !important;
  font-size: 1.1em !important;
  margin: 1em 0 !important;
  padding: 15px !important;
  background-color: #f9f9f9 !important;
  border-radius: 4px !important;
  border-left: 4px solid #ddd !important;
}

/* Capitalize first letter of sentences */
.transcript::first-letter,
div[data-video-id]::first-letter,
.transcript *::first-letter,
div[data-video-id] *::first-letter {
  text-transform: uppercase !important;
}

/* Fix HTML entities and formatting */
.transcript,
div[data-video-id] {
  white-space: pre-line !important;
}

/* Image wrapper and image styling */
.image-wrapper {
  max-width: 800px !important;
  margin: 1.5em auto !important;
  display: block !important;
}

/* Handle lazy-loaded images */
.aspect-ratio-original,
img[class*="aspect-ratio"],
.lazyload {
  display: block !important;
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
  opacity: 1 !important;
  visibility: visible !important;
}

/* Force image loading */
img[data-src] {
  min-height: 200px !important;
  background-color: #f5f5f5 !important;
}

/* Mobile optimization */
@media screen and (max-width: 768px) {
  .image-wrapper {
    padding: 0 15px !important;
  }
  
  .aspect-ratio-original,
  img[class*="aspect-ratio"] {
    width: 100% !important;
  }
}

/* Hide empty media elements */
.image-wrapper:empty,
.image-wrapper img[src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"],
.image-wrapper:not(:has(img:not([src^="data:image"]))),
[class*="player-wrapper"],
[class*="video-wrapper"] {
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

/* Hide Facebook embeds and zoomable pictures */
.embed-inner,
.zoomable-picture,
.zoomable-picture-button,
.fb-post,
[class*="embed-"],
[class*="zoomable"],
[class*="fb-"] {
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

/* Global image styling - excluding ads */
.article-content img:not([class*="ad"]),
.article-content--body-text img:not([class*="ad"]),
.RichTextArticleBody-body img:not([class*="ad"]),
[class*="article"] img:not([class*="ad"]):not([class*="advertisement"]),
[class*="content"] img:not([class*="ad"]):not([class*="advertisement"]),
img[src*="brightspotcdn"]:not([class*="ad"]),
img[srcset*="brightspotcdn"]:not([class*="ad"]) {
  max-width: 800px !important;
  width: 100% !important;
  height: auto !important;
  display: block !important;
  margin: 1.5em auto !important;
  object-fit: contain !important;
}

/* Mobile optimization for content images - excluding ads */
@media screen and (max-width: 768px) {
  img:not([class*="ad"]):not([class*="advertisement"]) {
    width: calc(100% - 30px) !important;
    margin: 1em auto !important;
    padding: 0 !important;
  }
}
