.date-filter-container {
  position: relative;
  display: inline-block;
}

.date-filter-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  height: 32px;
  white-space: nowrap;
}

.date-filter-button.active {
  background: #f0f7ff;
  border-color: #007AFF;
  color: #007AFF;
}

.calendar-icon {
  font-size: 14px;
}

.date-label {
  margin-right: 4px;
}

.clear-filter-button {
  background: none;
  border: none;
  padding: 0 4px;
  color: inherit;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  margin-left: auto;
}

/* Hide the default input */
.MuiInputBase-root {
  display: none;
} 