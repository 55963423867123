/* Layout.css */
.main-content {
  padding: 10px;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-height));
  padding-top: 15vh;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  visibility: hidden;
}

.loading-spinner-container.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in;
}

.spinner {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 4px solid #1c24f5;
  border-right: 4px solid #1c24f5;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .main-content {
    padding-top: 65px;
  }
}
