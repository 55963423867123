/* HeroSection.css */
.hero-section {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
}

.hero-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative; /* Enable positioning of child elements */
  cursor: pointer; /* Add pointer cursor for interactivity */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}



.hero-image-container {
  position: relative; /* For positioning the tag overlay */
  width: 100%;
  flex: 2; /* Increase space allocated to the image */
  overflow: hidden;
}

.hero-section-image {
  width: 100%;
  object-fit: cover;
}

.hero-tag {
  position: absolute;
  top: 12px; /* Slightly lower to give space */
  left: 12px;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */  color: #fff;
  padding: 6px 10px;
  font-size: 14px; /* Slightly larger font size */
  font-weight: 700;
  border-radius: 6px; /* More pronounced rounded edges */
  text-transform: uppercase;
}


.text-area {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px; /* Space between elements inside the text area */
}

.hero-headline {
  font-size: 2rem; /* Slightly smaller on mobile */
  font-weight: 700;
  color: #222; /* Slightly darker for better contrast */
}

.button {
  margin-top: 16px; /* Add space between the paragraph and button */
}

.paragraph {
  font-size: 0.9rem; /* Reduce font size slightly */
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.8; /* Increase line spacing for readability */
}

.article-date {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.article-author {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
}

@media (min-width: 768px) {
  .hero-card {
    flex-direction: row;
  }

  .hero-image-container {
    width: 60%;
    order: 2;
  }

  .text-area {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 0;
  }

  .hero-image-container {
    width: 100%;
  }

  .hero-section-image {
    height: auto; /* Ensure the image height adapts proportionally */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .text-area {
    order: 2;
  }

  .hero-headline {
    font-size: 1.5rem;
  }
}
